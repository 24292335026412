// 后台状态
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { watIdentity } from '@/data/data'

// 初始化状态
const initState = () => ({
  // 当前后台身份
  curIdentity: {
    id: '',
    name: '',
    isMerchant: false,
    merchantId: '',
    avatar: '',
    isHasStore: false,
  },
  // 权限列表
  identities: {
    merchants_role: [], // 商家角色
    merchant_stores_role: [], // 商家门店角色
  },
})

export const useAdminStore = defineStore(
  'admin',
  () => {
    const adminInfo = ref(initState())
    // 设置中台当前选中的商家
    const setCurIdentity = (val) => {
      // adminInfo.value.curIdentity = val
      Object.assign(adminInfo.value.curIdentity, val)
    }
    const setCurIdentityHasStore = (val) => {
      adminInfo.value.curIdentity.isHasStore = val
    }
    // 设置身份列表
    const setIdentities = (val) => {
      adminInfo.value.identities.merchants_role = val.merchants_role
      adminInfo.value.identities.merchant_stores_role = val.merchant_stores_role
    }

    // 更新活动身份对应的信息
    const setIdentityInfo = (val) => {
      const curIdentity = adminInfo.value.curIdentity
      const typeKey = curIdentity.isMerchant ? watIdentity.MERCHANT : watIdentity.STORE
      const identities = adminInfo.value.identities
      const index = identities[typeKey].findIndex((identity) => curIdentity.id === identity.id)
      if (index !== -1) {
        identities[typeKey][index] = { ...identities[typeKey][index], ...val }
      }
    }

    // 清除中台信息
    const clearAdminInfo = () => {
      adminInfo.value = initState()
    }

    return {
      adminInfo,
      setIdentities,
      setCurIdentity,
      setCurIdentityHasStore,
      setIdentityInfo,
      clearAdminInfo,
    }
  },
  {
    persist: true,
  },
)
