<template>
  <wd-config-provider :themeVars="themeVars">
    <slot />
    <wd-toast />
    <wd-message-box />
  </wd-config-provider>
</template>

<script lang="ts" setup>
import type { ConfigProviderThemeVars } from 'wot-design-uni'

const themeVars: ConfigProviderThemeVars = {
  colorTheme: '#ff6600',
  buttonPrimaryBgColor: '#ff6600',
  swiperRadius: '0',
  cellWrapperPadding: '1rem',
  // // buttonPrimaryColor: '#fff',
  // // --wot-tabs-nav-line-bg-color
  // tabsNavActiveColor: '#ff6600',
  // tabsNavLineBgColor: '#ff6600',
  // radioCheckedColor: '#ff6600',
}
</script>

<style lang="scss" scoped></style>
