<template>
  <wd-tabbar
    fixed
    :model-value="tabbarStore.curIdx"
    bordered
    safeAreaInsetBottom
    placeholder
    @change="selectTabBar"
  >
    <template v-for="(item, idx) in tabbarList" :key="item.path">
      <wd-tabbar-item
        v-if="item.iconType === 'wot'"
        v-show="idx !== 1 || (idx === 1 && curIdentity.isHasStore)"
        :title="item.text"
        :icon="item.icon"
      ></wd-tabbar-item>
      <wd-tabbar-item
        v-else-if="item.iconType === 'unocss' || item.iconType === 'iconfont'"
        :title="item.text"
      >
        <template #icon>
          <view
            h-40rpx
            w-40rpx
            :class="[item.icon, idx === tabbarStore.curIdx ? 'is-active' : 'is-inactive']"
          ></view>
        </template>
      </wd-tabbar-item>
      <wd-tabbar-item
        v-else-if="item.iconType === 'local'"
        :title="item.text"
        activeColor="#ff6600"
        inactiveColor="#707070"
        v-show="idx !== 1 || (idx === 1 && curIdentity.isHasStore)"
      >
        <template #icon>
          <image
            :src="idx === tabbarStore.curIdx ? item.selectedIcon : item.icon"
            h-48rpx
            w-48rpx
          />
        </template>
      </wd-tabbar-item>
    </template>
  </wd-tabbar>
</template>

<script setup lang="ts">
// unocss icon 默认不生效，需要在这里写一遍才能生效！注释掉也是生效的，但是必须要有！
// i-carbon-code
import { tabbarStore } from '@/store/tabbar'
import { adminTabBar } from '@/pages.json' // tabbar配置
import { useAdminStore } from '@/store'
const adminStore = useAdminStore()
// 当前身份
const curIdentity = computed(() => adminStore.adminInfo.curIdentity)
/** tabbarList 里面的 path 从 pages.config.ts 得到 */
const tabbarList = adminTabBar.list.map((item) => ({
  ...item,
  path: `/${item.pagePath}`,
  selectedIcon: `/${item.selectedIcon}`,
  icon: `/${item.icon}`,
}))

function selectTabBar({ value: index }) {
  if (tabbarStore.curIdx === index) return

  tabbarStore.setCurIdx(index)
  const url = tabbarList[index].path
  if (index === 0) {
    // 第一个跳转用户首页是tabbar
    uni.switchTab({ url })
  } else {
    uni.redirectTo({ url })
  }
}

// onLoad(() => {
//   // 解决原生 tabBar 未隐藏导致有2个 tabBar 的问题
//
//   // uni.hideTabBar()
//
// })
</script>

<style lang="scss" scoped>
:deep(.is-active) {
  color: #ff6600 !important;
}
:deep(.is-inactive) {
  color: #707070 !important;
}
</style>
