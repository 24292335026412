<script setup lang="ts">
import { useAuthStore } from './store'

const authStore = useAuthStore()
const tokenInfo = authStore.authInfo.tokenInfo
onLaunch(() => {
  // 首次启动时，检查 token 是否过期
  if (tokenInfo && tokenInfo?.tokenOutTime < new Date().getTime()) {
    authStore.clearAuthInfo()
  }
  // 解决原生 tabBar 未隐藏导致有2个 tabBar 的问题

  // uni.hideTabBar({
  //   fail(err) {
  //     console.log('隐藏tabbar失败: ', err)
  //   },
  // })

})
</script>

<style lang="scss">
/* stylelint-disable selector-type-no-unknown */
@import '@assets/styles/variable.css';
@import '@assets/styles/global';
@import '@assets/styles/iconfont.css';

@media (min-width: 1024px) {
  html {
    /* 设置page.config.ts不限制最大宽度maxWidth */
    // font-size: calc(16px + (16 / 375) * (100vw - 375px)) !important;
    /* 设置page.config.ts最大宽度maxWidth: 1024px, */
    font-size: calc(16px + (16 / 375) * (1024px - 375px)) !important;
  }
}
/* 隐藏所有滚动条 */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* Hide scrollbar for Webkit browsers (Chrome, Safari) */
*::-webkit-scrollbar {
  display: none;
}

html {
  font-family: 'PingFang SC', 'Microsoft YaHei', sans-serif;
  color: #333;
  background-color: var(--wat-bg-color);
}
button::after {
  border: none;
}

swiper,
scroll-view {
  flex: 1;
  height: 100%;
  overflow: hidden;
}

image {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

// 单行省略，优先使用 unocss: text-ellipsis
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 两行省略
.ellipsis-2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

// 三行省略
.ellipsis-3 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
// 平板下拉刷新行内样式是写死的，这里写在class上可以让px2rem转成rem
:deep(.uni-page-refresh) {
  clip: rect(-84px, 45px, 45px, -5px) !important;
}
</style>
