<template>
  <wd-config-provider :themeVars="themeVars">
    <view class="">
      <slot />
    </view>
    <wd-toast />
    <wd-message-box />
    <admin-tabbar />
  </wd-config-provider>
</template>

<script lang="ts" setup>
import type { ConfigProviderThemeVars } from 'wot-design-uni'
const themeVars: ConfigProviderThemeVars = {
  colorTheme: '#ff6600',
  buttonPrimaryBgColor: '#ff6600',
  // colorTheme: 'red',
  // buttonPrimaryBgColor: '#07c160',
  // buttonPrimaryColor: '#07c160',
}
</script>
