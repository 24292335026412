// 优惠券信息
import { defineStore } from 'pinia'
import { ref } from 'vue'

const initState = {
  couponDetail: {}, // 优惠券详情
  nearbyParking: [], // 附近车场
}

export const useCouponStore = defineStore(
  'coupon',
  () => {
    const couponData = ref({ ...initState })
    // 设置优惠券详情
    const setCouponDetail = (val) => {
      couponData.value.couponDetail = val
    }
    // 设置附近停车场
    const setNearbyParking = (val) => {
      couponData.value.nearbyParking = val
    }
    // 清空优惠券信息
    const clearCoupon = () => {
      couponData.value = { ...initState }
    }
    return {
      couponData,
      setCouponDetail,
      setNearbyParking,
      clearCoupon,
    }
  },
  {
    persist: true,
  },
)
