/**
 * tabbar 状态，增加 storageSync 保证刷新浏览器时在正确的 tabbar 页面
 */
export const tabbarStore = reactive({
  curIdx: uni.getStorageSync('admin-tabbar-index') || 0,
  setCurIdx(idx: number) {
    this.curIdx = idx
    uni.setStorageSync('admin-tabbar-index', idx)
  },
})
