// 身份
export const watIdentity = {
  MERCHANT: 'merchants_role',
  STORE: 'merchant_stores_role',
}
// 身份权限标识
export const identity = {
  MERCHANT: 'merchants_role',
  STORE: 'merchant_stores_role',
}
// 商家和门店的请求头key
export const headerKey = {
  MERCHANT: 'Wat-Community-Merchant-Id', // 商家
  STORE: 'Wat-Community-Merchant-Store-Id', // 门店
}
