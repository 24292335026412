<template>
  <wd-config-provider :themeVars="themeVars">
    <view class="">
      <slot />
    </view>
    <wd-toast />
    <wd-message-box />
    <!-- <fg-tabbar /> -->
  </wd-config-provider>
</template>

<script lang="ts" setup>
import type { ConfigProviderThemeVars } from 'wot-design-uni'

const themeVars: ConfigProviderThemeVars = {
  colorTheme: '#64a0ff',
  swiperRadius: '0',
  // buttonPrimaryBgColor: '#64a0ff',
  // buttonPrimaryColor: '#07c160',
}
</script>
